import React from 'react';
import "./Back.scss"
import { Link } from 'react-router-dom';

export default function Back(){
  return (
    <>
      <Link className='back-btn' to={"/"}>
        <span>🡠 back</span>
      </Link>
    </>
  );
};
