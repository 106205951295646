import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import "./Header.scss";
import EllingtonLogo from "../../static/img/header_img/ellington_logo.svg"
import img1 from "../../static/img/header_img/img1.svg"
import loop from "../../static/img/header_img/search_loop.svg"

export default function Header(){

  const [selectComActive, setSelectComActive] = useState(false);
  const [selectProActive, setSelectProActive] = useState(false);

  const arrayCom = [
    'Arjan', 
    'Business Bay', 
    'Downtown', 
    'Dubai Hills Estate', 
    'Emirates Hills', 
    'Jaddaf', 
    'Jumeirah Lake Towers', 
    'Jumeirah Village Circle', 
    'Jumeirah Village Triangle', 
    'La Mer', 
    'Mohammed Bin Rashid City', 
    'Palm Jumeirah',
  ];
  const arrayPro = [
    'Claydon House', 
    'Rosemont Residences', 
    'art bay', 
    'One River Point', 
    'Mercer House', 
    'Ellington Views I', 
    'Ellington Views II', 
    'Porto Playa', 
    'Arbour View', 
    'Emirates Hills Villa', 
    'The Crestmark', 
    'Belgravia Square', //
    'Belgravia Heights', 
    'The Sanctuary', 
    'UH by Ellington (East)', 
    'UH by Ellington (West)', 
    'Ocean House', 
    'The Highbury', 
    'Ellington Beach House', 
    'Ellington House II', 
    'The Quayside', 
    'DTI', 
    'Hillmont Residences', 
    'Hamilton House', //
    'Belgravia III', 
    'Ellington House III', 
    'Ellington House IV', 
    'Belmont Residences', 
    'Berkley Place', 
    'Kensington Waters', 
    'Somerset Mews', 
    'view all proporties',
  ];

  const selectComHandleToggle = () => {
    setSelectComActive((prev) => !prev);
    setSelectProActive(false);
  }
  const selectProHandleToggle = () => {
    setSelectProActive((prev) => !prev);
    setSelectComActive(false);
  }

  return (
    <>
        <header className="header">
          <Link to={"/"}>
            <img
              className='logo' 
              src={EllingtonLogo} 
              alt="ellington-image" 
            />
          </Link>
          <div className="span-con">
            <div className="span-select" style={{textAlign: 'right'}}>
              <span
                className='span'
                onClick={selectComHandleToggle}
              >
                our communities
              </span>
              {selectComActive ? 
                <div className={`header-select-bottom${selectComActive ? " activeCom" : ""}`}>
                  {arrayCom.map((item) => 
                    <span>{item}</span>
                  )}
                </div> 
                : 
                <></>
              }
            </div>
            <span className="separator">|</span>
            <div className="span-select" style={{textAlign: 'left'}}>
              <span
                className='span'
                onClick={selectProHandleToggle}
              >
                our properties
              </span>
              {selectProActive ? 
                <div className={`header-select-bottom${selectProActive ? " activePro" : ""}`}>
                  {arrayPro.map((item) => 
                    <span>{item}</span>
                  )}
                </div> 
                : 
                <></>
              }
            </div>
          </div>
          <div className="con-search">
            <img
              src={img1} 
              alt="" 
              className='img1'
            />
            <img
              className='loop' 
              src={loop} 
              alt="loop" 
            />
            <input
              placeholder="SEARCH" 
              type="text" 
            />
          </div>
        </header>
        {/* {selectComActive ? 
          <div className={`header-select-bottom${selectComActive ? " activeCom" : ""}`}>
            {arrayCom.map((item) => 
              <span>{item}</span>
            )}
          </div> 
          : 
          <></>
        } */}
    </>
  );
};
