import React from 'react';
import "./ZoomButton.scss"
import ZoomLoop from "../../static/img/Union.svg"

export default function ZoomButton(){
  return (
    <>
      <div className="zoom-button">
        <img src={ZoomLoop} alt="zoom-loop" />
        <span>zoom</span>
      </div>
    </>
  );
};
