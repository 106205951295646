import React, {useState} from 'react';
import "./VisibilitySelect.scss"
import SelectArrow from "../../static/img/Arrow 2.svg"

export default function VisibilitySelect(){
  const [selectActive, setSelectActive] = useState(false);

  const array = [
    'landmarks', 
    'malls', 
    'schools', 
    'hospitals'
  ];

  const selectHandleToggle = () => {
    setSelectActive((prev) => !prev);
  }

  return (
    <>
      <div className="select-con">
        <div onClick={selectHandleToggle} className={`select${selectActive ? " active" : ""}`}>
          <img src={SelectArrow} alt="top-arrow" />
          <span>visibility</span>
        </div>
        {selectActive ? 
          <div className="select-bottom">
            {array.map((item) => 
              <>
                <label class="form-control">
                  <input type="checkbox" name="checkbox" />
                  <span>{item}</span>
                </label>
              </>
            )}
          </div>
          :
          <></>
        }
      </div>
    </>
  );
};
