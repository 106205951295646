import React from 'react';
import './StartButton.scss'
import { Link } from 'react-router-dom';

export default function StartButton({title, link}){
  return (
    <>
      <Link to={link} className="start-button-wrapper">
        <span>{title}</span>
      </Link>
    </>
  );
};
