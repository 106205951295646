import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Start from "./pages/start/Start"
import Dubai from "./pages/dubai/Dubai"
import EmptyPage from "./pages/empty-page/EmptyPage";
import Header from "./components/header/Header";
import HeaderEmptyVoid from "./components/header-empty-void/HeaderEmptyVoid";

import PageTransition from "./animations/PageTransition";
import { AnimatePresence } from "framer-motion";
import Compass from "./components/compass/Compass";

function AnimatedRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route
                    path="/" 
                    element={
                        <PageTransition>
                            <Start />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/dubai" 
                    element={
                        <PageTransition>
                            <Dubai />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/abu-dhabi" 
                    element={
                        <PageTransition>
                            <EmptyPage />
                        </PageTransition>
                    } 
                />
                <Route
                    path="/ras-al-khaimah" 
                    element={
                        <PageTransition>
                            <EmptyPage />
                        </PageTransition>
                    } 
                />
            </Routes>
        </AnimatePresence>
    );
}

function AppRouter() {
    return (
        <Router>
            <Header />
            <HeaderEmptyVoid />
            <AnimatedRoutes />
            <Compass/>
        </Router>
    );
}

export default AppRouter;
