import React from 'react';
import "./HeaderEmptyVoid.scss"

export default function HeaderEmptyVoid(){
  return (
    <>
      <div className="header-empty-void"></div>
    </>
  );
};
