import React from 'react';
import "./EmptyPage.scss"

export default function EmptyPage(){
  return (
    <>
      <div className="empty-page-wrapper">
        <span>This project is unavailable now</span>
      </div>
    </>
  );
};
