import React from 'react';
import "./Compass.scss"
import CompassImg from "../../static/img/Compass.svg"

export default function Compass(){
  return (
    <>
      <img className='compass' src={CompassImg} alt="compass" />
    </>
  );
};
