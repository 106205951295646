import React from 'react';
import "./Dubai.scss"
import Back from '../../components/back-button/Back';
import VisibilitySelect from '../../components/visibility-select/VisibilitySelect';
import ZoomButton from '../../components/zoom-button/ZoomButton';

export default function Dubai(){
  return (
    <>
      <div className="dubai-wrapper">
        <div className="btns">
          <Back/>
          <VisibilitySelect/>
          <ZoomButton/>
        </div>
      </div>
    </>
  );
};
